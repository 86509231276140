import { expressEventListener, expressQuerySelector, expressQuerySelectorAll, expressRemoveClass, show } from "../common/html";
import { postHtmlRequest, postRequest } from "../utils/fetch";
import { initForm } from "./form";
import {addParameter, getFullQuerystring, getParameter, updateParameter} from "../utils/querystring";
import { createPopup, IPopup } from "./popup-new";
import { createButton, IButton } from "./button";
import { IDisposable } from "../utils/disposable";
import { createPageLog, PageLogStyle } from "./page-log";
import { stringToBase64 } from "../utils/string";

enum ExternalLoginProvider {
	Google = 0,
	Facebook = 1
}

interface SignInButton {
	Provider: ExternalLoginProvider;
	ButtonEl: HTMLElement;
	Button: IButton;
}
const signInButtons: SignInButton[] = [];

const getMediaParterForLabel = function (mediaStorePartnerType: string): string {
	if (mediaStorePartnerType === 'googlephotos') {
		return 'Google';
	}

	return mediaStorePartnerType;
};

export interface ICreateLoginRegisterDependencies {
	onSuccess?: () => void;
	pageLogEl?: HTMLElement;
	closeLoginPopup?: () => void;
}

export function CreateLoginRegisterComponents(addSuccessQueryToRedirectUrl = false) {
	if (!window.context.authenticationPopupEnabled) return;

	const loginComponents = expressQuerySelectorAll(document, '.technical-authentication-link');
	const createAuthenticationPopup = (mode?: string) => {
		createPopup({
			dialogId: 'LoginRegisterComponent',
			view: 'Authentication',
			data: { Email: getParameter('Email'), Mode: mode || 'login' },
			forceRefresh: true
		}).then(res => {
			if (!res.popup) return;
			window.loginWatcher && window.loginWatcher.cancel();
			CreateLoginRegister(res.popup, {
				onSuccess: () => {
					addSuccessQueryToRedirectUrl
					? window.location.href = addParameter('success', 'true', window.location.href)
					: window.location.reload();
				},
				pageLogEl: expressQuerySelector(document, '.technical-top-message-log', false),
				closeLoginPopup: res.close
			});
			res.openAsync();
		});
	};

	loginComponents.forEach(x => {
		const button = expressQuerySelector(x, '.technical-authentication-link-button', false);
		button && button.addEventListener('click', (e: Event) => { e.preventDefault(); createAuthenticationPopup(button.getAttribute('data-mode')); });

		const registerLink = expressQuerySelector(x, '.technical-authentication-register-link');
		registerLink && registerLink.addEventListener('click', (e: Event) => { e.preventDefault(); createAuthenticationPopup(registerLink.getAttribute('data-mode')); });
	});
}

export function CreateLoginRegister(containerEl?: HTMLElement, deps?: ICreateLoginRegisterDependencies) {
	const loginRegisterEls = expressQuerySelectorAll<HTMLElement>(containerEl ?? document, '.technical-login-register-container');
	const { onSuccess, pageLogEl, closeLoginPopup } = deps;
	const loginComponents = loginRegisterEls.map(x => {
		return ExpressLoginComponent(x, {
				toggleElementClicked: (loginMode: boolean) => { loginComponents.forEach(x => x.toggleShow(x.loginMode === loginMode)); },
				onSuccess: onSuccess,
				pageLogEl,
				closeLoginPopup
			});
	});
}
export interface LoginComponentDeps {
	toggleElementClicked: (loginMode) => void;
	onSuccess: () => void;
	pageLogEl?: HTMLElement;
	closeLoginPopup?: () => void;
}

interface IExpressLoginRequest {
	Email: string;
	Password: string;
	RememberMe: string;
	RedirectUrl: string;
	CurrentUrl: string;
}
interface IExpressLoginResult {
	redirectUrl: string;
	isNotAuthenticated: boolean;
	errorMsg: string;
	blockingErrorMsg: string;
	blockingErrorLabel: string;
	accountId: string;
	cartId: string;
	isSoftDeleted: boolean;
}
interface ILoginSubmitResult {
	readonly redirectUrl?: string;
	readonly isNotAuthenticated?: boolean;
	readonly errorMsg?: string;
	readonly blockingErrorMsg?: string;
	readonly accountId?: string;
	readonly cartId?: string;
	readonly isSoftDeleted?: boolean;
}
interface IExpressRegisterRequest {
	Email: string;
	Password: string;
	ConfirmedPassword: string;
	PersonalMessage: string;
	NewsLetter: string;
	Conditions: string;
	RedirectUrl: string;
}
interface IExpressRegisterResponse {
	success: boolean;
	accountInOtherDomain: string;
	redirectUrl: string;
	accountId: string;
	cartId: string;
	switchToLogin: boolean;
	message: string;
	email: string;
	accountBlocked: string;
	isSoftDeleted: true;
}
interface ILoginUserAtSmartPhotoResult {
	readonly accountData?: IAccountData;
	readonly loginResult: ILoginResult;
	readonly accountType: string;
	readonly cartView?: string;
	readonly msgView?: string;
	readonly redirectUrl?: string;
	readonly success?: boolean;
}
interface IAccountData {
	readonly DeliveryAddressData: IAddress;
	readonly BillingAddressData: IAddress;
	readonly Email: string;
	readonly FirstName: string;
	readonly HideNewsletter: boolean;
	readonly LastName: string;
	readonly Message?: string;
	readonly SendNewsletter?: boolean;
	readonly ShowProfileForm?: boolean;
	readonly ShowProfileFormExtraText?: boolean;
}
export interface IAddress {
	HouseNumber?: string;
	Id?: string;
	IsDefault?: boolean;
	City?: string;
	RegionCountryISO?: string;
	readonly Country?: string;
	FirstName?: string;
	LastName?: string;
	CompanyName?: string;
	Line1?: string;
	Line2?: string;
	Line3?: string;
	Line4?: string;
	ZipCode?: string;
	VatNumber?: string;
	Street?: string;
	BoxNumber?: string;
	ExtraAddressInformation?: string;
}
export interface ICreateFacebookLoginButtonDependencies {
	readonly loginUserAtSmartPhoto: (uid: string, accessToken: string, expire: number, mediaStorePartnerType: string, newEmail: string, skipLogin: boolean, onOrderFlow: boolean, async: boolean) => Promise<void>;
}
interface ILoginResult {
	AccountId: string;
	tempAccountId: string;
	readonly PersonId: string;
	readonly cartId: string;
	readonly Email: string;
	readonly redirectUrl: string;
	readonly success: string;
	readonly PopUpCase: string;
}
export function ExpressLoginComponent(containerEl: HTMLElement, deps: LoginComponentDeps) {
	const { toggleElementClicked, onSuccess, closeLoginPopup } = deps;
	const loginMode = containerEl.dataset.mode === 'register';
	const form = expressQuerySelector<HTMLFormElement>(containerEl, '.technical-login-register-form', true);
	const externalLoginSubmitButtons = expressQuerySelectorAll<HTMLButtonElement>(containerEl, '.technical-external-login');
	const toggleEl = expressQuerySelector<HTMLElement>(containerEl, '.o-authentication-form__footer .technical-label a', false); // link in label
	const forgetPassword = expressQuerySelector<HTMLElement>(containerEl, '.technical-forgot-password', false);

	const login = async (data): Promise<void> => {
		try {
            const res = await postRequest<IExpressLoginRequest, IExpressLoginResult>('/ajaxxhr/Login/NormalLogin',
                {
                    Email: data['email'],
                    Password: stringToBase64(data['password']),
                    RememberMe: data['rememberme'],
                    RedirectUrl: decodeURIComponent(getParameter('ReturnUrl')),
                    CurrentUrl: window.location.pathname
                },
                { headers: { 'Content-type': 'application/json; charset=utf-8' } });
            const _ = handleLoginResult(res, data);
            return;
        } catch (err) {
            const res_2 = await postHtmlRequest("/apicore/logapi/LogInformation", { InformationText: `user logged in with express with errors: ${err}`, Error: true }, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
            return;
        }
	};
	const createIsSoftDeletedPopup = (view: string, dialogId: string, data: any) => {
		const undeleteAsync = async (formData: any) => {
			const response = await postRequest<any, ILoginResult | ILoginUserAtSmartPhotoResult>('/ajaxxhr/Login/UndeleteLogin', formData, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
            if (response && ((response as any).PopUpCase) || (response as any).loginResult) {
                handleExternalLoginResult(
                    response,
                    data.LoginUserAtSmartPhotoModelData.MediaStorePartnerType,
                    data.LoginUserAtSmartPhotoModelData.Async,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    data.LoginUserAtSmartPhotoModelData.Uid,
                    data.LoginUserAtSmartPhotoModelData.AccessToken);
            } else {
                handleLoginResult(response as ILoginSubmitResult as IExpressLoginResult, formData);
            }
		};

		createPopup({
			view: view,
			dialogId: dialogId,
			data: {
				Question: 'Label.UserLogin.LoginPage.DeletedAccount.Description',
				Title: 'Label.UserLogin.LoginPage.DeletedAccount.Title',
				OkMnemonic: 'Label.UserLogin.LoginPage.DeletedAccount.Continue',
				CancelMnemonic: 'Label.UserLogin.LoginPage.DeletedAccount.Cancel'
			}
		})
			.then(popup => {
				const dialog = expressQuerySelector<HTMLElement>(document, '#' + dialogId, true);
				const okButton = expressQuerySelector<HTMLElement>(dialog, '.technical-remove-confirmation-yes-button', true);
				const cancelButton = expressQuerySelector<HTMLElement>(dialog, '.technical-remove-confirmation-no-button', true);

				okButton.addEventListener("click", function () { popup.close(); undeleteAsync(data); });
				cancelButton.addEventListener("click", function () { popup.close(); });

				popup.openAsync();
			});
	};
	const createPopupWithBodyLabel = (view: string, dialogId: string, bodyLabel: string, extraPopupCss?: string, extraPopupSetup?: (popupEl: IPopup) => void) => {
		let replacements: string[] = [];
		if (bodyLabel.indexOf('|') > 0) {
			const bodyArr = bodyLabel.split('|');
			bodyLabel = bodyArr[0];
			replacements = bodyArr[1].split(',');
		}

		createPopup({
			view: view,
			dialogId: dialogId,
			data: {
				BodyLabel: bodyLabel,
				BodyReplacements: replacements,
				OkButtonLabel: 'Label.CloseButtonText',
				ExtraPopupCss: `p-default ${extraPopupCss}`
			}
		}).then(res => {
			const dialog = expressQuerySelector<HTMLElement>(document, '#' + dialogId, true);
			const okButton = expressQuerySelector<HTMLElement>(dialog, '.technical-general-popup-ok-button', true);

			okButton.addEventListener("click", function () { res.close(); });
			extraPopupSetup && extraPopupSetup(res);

			res.openAsync();
		});
	};
	const externalLogin = (containerEl: HTMLButtonElement, provider: ExternalLoginProvider) => {
		switch (provider) {
			case ExternalLoginProvider.Google: return createGoogleLogin(containerEl, {
				appId: containerEl.getAttribute("app_id") || '',
				loginCallback: (uid, accessToken, expire, scope) => loginUserAtSmartPhoto(uid, accessToken, expire, "googlephotos", "", false, !!containerEl.getAttribute("data-orderflow"), false, scope)
			});
			case ExternalLoginProvider.Facebook: return createFacebookLoginButton(containerEl, { loginUserAtSmartPhoto: loginUserAtSmartPhoto });
		}
	};
	const loginUserAtSmartPhoto = async (uid: string, accessToken: string, expire: number, mediaStorePartnerType: string, newEmail: string, skipLogin: boolean, onOrderFlow: boolean, async: boolean, scope?: string) => {
		const res = await postRequest<{ userId: string; accessToken: string; expire: number; mediastorePartnerType: string; newEmail: string; skipLogin: boolean; onOrderFlow: boolean; async: boolean; scope?: string; }, ILoginResult | ILoginUserAtSmartPhotoResult>('/ajaxxhr/Login/LoginUserAtSmartPhoto', {
            userId: uid,
            accessToken: accessToken,
            expire: expire,
            mediastorePartnerType: mediaStorePartnerType,
            newEmail: newEmail,
            skipLogin: skipLogin,
            onOrderFlow: onOrderFlow,
            async: async,
            scope: scope
        }, { headers: { 'Content-type': 'application/json; charset=utf-8' } }
        );
        return handleExternalLoginResult(res, mediaStorePartnerType, async, undefined, undefined, undefined, undefined, uid, accessToken);
	};

	const handleLoginResult = (res: IExpressLoginResult, data: any) => {
		if (res.isNotAuthenticated && !res.errorMsg && !res.blockingErrorMsg) res.errorMsg = 'Something went wrong';
		if (res.errorMsg) {
			// show error in page
			formComponent.inputs['email'].setError(res.errorMsg);
			formComponent.inputs['password'].setError(res.errorMsg);
		}

		if (res.isSoftDeleted) {
			const formData = {
				Email: data['email'],
				Password: stringToBase64(data['password']),
				RememberMe: data['rememberme'],
				RedirectUrl: decodeURIComponent(getParameter('ReturnUrl'))
			};

			// show popup
			createIsSoftDeletedPopup("ConfirmDialog", "isSoftDeleted", formData);
		}

		if (res.blockingErrorLabel) {
			if (formComponent) {
				formComponent.inputs['password'].clear();
				formComponent.inputs['password'].setValue('');
			}

			// show popup
			createPopupWithBodyLabel("AccountBlocked", "blockingErrorMsg", res.blockingErrorLabel, '', (popupEL: IPopup) => {
				const link = expressQuerySelector(popupEL.popup, 'a', false);
				if (!link) return;

				link.addEventListener('click', e => {
					e.preventDefault();
					popupEL.close();
					openForgotPasswordPopup();
				});
			});
		}

		if (!res.isNotAuthenticated && res.redirectUrl && res.redirectUrl.length > 0) {
			const dataLayer = window.dataLayer || [];
			dataLayer.push({ 'event': 'login' });
			const iframe = expressQuerySelector<HTMLIFrameElement>(document, '#dataLayerIframe', false);
			if (iframe && iframe.contentWindow && (iframe.contentWindow as any).pushDataLayer) {
				(iframe.contentWindow as any).pushDataLayer({ 'event': 'login' });
			}

			if (!getParameter('embed')) {
				// redirect
				return onSuccess ? onSuccess() : window.location.href = res.redirectUrl;
			} else if (typeof parent != undefined) {
				// @ts-ignore
				parent.eXpress && parent.eXpress.loggedOn && parent.eXpress.loggedOn({ accountId: res.accountId, cartId: res.cartId });
				parent.document.dispatchEvent(new CustomEvent('loggedOn.loginDialog'));
			}
		}
	};
	const handleExternalLoginResult = (
		response: ILoginResult | ILoginUserAtSmartPhotoResult,
		mediaStorePartnerType: string,
		async: boolean,
		accountId = "",
		tempAccountId = "",
		showConfirmationBeforeRedirect = false,
		loginNotCorrect = false,
		uid = "",
		accessToken = "") => {
		let loginUserAtSmartPhotoResponse: ILoginUserAtSmartPhotoResult | undefined = undefined;
		let loginResult: ILoginResult | undefined = undefined;

		if (response && (response as any).loginResult)
			loginUserAtSmartPhotoResponse = response as ILoginUserAtSmartPhotoResult;
		else
			loginResult = response as ILoginResult;

		let asyncSuccess = true;
		if (async) {
			if (loginResult && loginResult.success == 'true') {
				asyncSuccess = false;
			}
		}
		if (async && asyncSuccess && loginUserAtSmartPhotoResponse) {
			loginResult = loginUserAtSmartPhotoResponse.loginResult as ILoginResult;
		}
		if (asyncSuccess && loginResult && loginResult.success == 'true') {
			if (loginResult.redirectUrl) {
				if (!getParameter('embed')) {
					onSuccess ? onSuccess() : window.location.href = loginResult.redirectUrl ? window.location.origin + loginResult.redirectUrl : window.location.origin;
				} else if (typeof parent != undefined) {
					// @ts-ignore
					parent.eXpress && parent.eXpress.loggedOn && parent.eXpress.loggedOn({ accountId: loginResult.AccountId, cartId: loginResult.CartId });
					parent.document.dispatchEvent(new CustomEvent('loggedOn.loginDialog'));
				}
			}
		} else if (loginResult) {
			// We need to correct the result for TEMP accounts (only Popupcase and success are returned)
			if (!loginResult.AccountId && accountId) loginResult.AccountId = accountId;
			if (!loginResult.tempAccountId && tempAccountId) loginResult.tempAccountId = tempAccountId;
			handleLoginFailed(loginResult, mediaStorePartnerType, loginNotCorrect, async, getMediaParterForLabel(mediaStorePartnerType), uid, accessToken);
		}
	};
	const handleLoginFailed = (
		loginResult: ILoginResult,
		mediaStorePartnerType: string,
		loginNotCorrect: boolean,
		async: boolean,
		mediaStorePartnerLabel: string,
		uid = "",
		accessToken = "",
		expire = 0,
		skipLogin = false) => {

		const alreadyTriedNewEmail = false;
		let accountInOtherDomainCalled = 0;

		switch (loginResult.PopUpCase) {
			case "AccountDeleted":
				createIsSoftDeletedPopup("ConfirmDialog", "isSoftDeleted",  {
					Email: loginResult.Email,
					Password: "",
					RememberMe: false,
					RedirectUrl: decodeURIComponent(getParameter('ReturnUrl')),
					LoginUserAtSmartPhotoModelData: {
						UserId: uid,
						AccessToken: accessToken,
						Expire: expire,
						MediaStorePartnerType: mediaStorePartnerType,
						NewEmail: "",
						SkipLogin: skipLogin,
						OnOrderFlow: false,
						Async: async
					}
				});
				break;
			case "AccountBlocked":
				createPopup({
					dialogId: 'AccountBlocked',
					view: 'GenericPopup',
					data: {
						BodyLabel: 'Label.UserLogin.AccountBlocked',
						OkButtonLabel: 'Label.OkButtonText',
						ExtraPopupCss: `p-default`
					}
				}).then(res => {
					const okButton = expressQuerySelector<HTMLElement>(res.popup, '.technical-general-popup-ok-button', true);
					okButton.addEventListener("click", function () { res.close(); });
					res.openAsync();
				});
				break;
			case "AskPassword":
				createPopup({
					dialogId: 'AskPassword',
					view: 'AskPasswordDialog',
					data: {
						LoginNotCorrect: loginNotCorrect,
						AccountId: loginResult.AccountId,
						AlreadyTriedEmail: alreadyTriedNewEmail,
						MediaStorePartnerLabel: mediaStorePartnerLabel
					},
					forceRefresh: true
				}).then(res => {
					const formEl = expressQuerySelector<HTMLFormElement>(res.popup, ".technical-ask-password-form", true);
					const sendPassWordLinkEl = expressQuerySelector<HTMLAnchorElement>(res.popup, ".technical-send-password-link", true);
					const passwordSendEl = expressQuerySelector<HTMLElement>(res.popup, ".technical-password-send", true);

					const confirmAskPassword = async (password: string) => {
						const singleSignOnResponse = await postRequest<{ accountId: string; tempAccountId: string; mediastorePartnerType: string; password: string; async: boolean; }, ILoginResult | ILoginUserAtSmartPhotoResult>('/ajaxxhr/Login/SingleSignOnConfirmPassword', {
                            accountId: loginResult.AccountId,
                            tempAccountId: loginResult.tempAccountId,
                            mediastorePartnerType: mediaStorePartnerType,
                            password: stringToBase64(password),
                            async: async
                        }, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
                        res.close();
                        handleExternalLoginResult(singleSignOnResponse, mediaStorePartnerType, async, loginResult.AccountId, loginResult.tempAccountId, true, true);
					};

					const sendPassword = (email: string) => {
						return postRequest('/ajaxxhr/Login/SingleSignOnSendPassword', {
							email: email,
							url: encodeURIComponent(window.location.href)
						}, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
					};

					initForm(formEl, {
						onSubmitForm: (formData) => confirmAskPassword(formData["password"]),
						submitOnEnter: true
					});

					sendPassWordLinkEl.addEventListener('click', e => {
						e.preventDefault();

						show(passwordSendEl);
						const email = sendPassWordLinkEl.dataset.email;
						email && sendPassword(email);
					});

					res.openAsync();
				});
				break;
			case "AlreadyLoggedIn":
				createPopup({
					dialogId: 'AlreadyLoggedIn',
					view: 'GenericPopup',
					data: {
						BodyLabel: 'Label.UserLogin.LoginPage.AlreadyLoggedIn',
						OkButtonLabel: 'Label.OkButtonText',
						ExtraPopupCss: `p-default`
					}
				}).then(res => {
					const okButton = expressQuerySelector<HTMLElement>(res.popup, '.technical-general-popup-ok-button', true);
					okButton.addEventListener("click", function () { res.close(); });
					res.openAsync();
				});
				break;
			case "FatalErrorMessage":
				createPopup({
					dialogId: `${mediaStorePartnerLabel}_FatalErrorMessage`,
					view: 'GenericPopup',
					data: {
						BodyLabel: 'Label.SingleSignOn.FatalError',
						OkButtonLabel: 'Label.OkButtonText',
						ExtraPopupCss: `p-default`,
						BodyReplacements: [mediaStorePartnerLabel]
					}
				}).then(res => {
					const okButton = expressQuerySelector<HTMLElement>(res.popup, '.technical-general-popup-ok-button', true);
					okButton.addEventListener("click", function () { res.close(); });
					res.openAsync();
				});
				break;
			// case "ConfirmEmail": break; // WEB-3835 => no longer used
			// case "OtherEmail": break;// WEB-3835 => no longer used
			case "FbIdAlreadyConnected":
				createPopup({
					dialogId: `${mediaStorePartnerLabel}_AlreadyConnected`,
					view: 'GenericPopup',
					data: {
						BodyLabel: `Label.Profile.SingleSignOn.${mediaStorePartnerLabel}.Error.AlreadyConnected`,
						OkButtonLabel: 'Label.OkButtonText',
						ExtraPopupCss: `p-default`,
						BodyReplacements: [mediaStorePartnerLabel]
					}
				}).then(res => {
					const okButton = expressQuerySelector<HTMLElement>(res.popup, '.technical-general-popup-ok-button', true);
					okButton.addEventListener("click", function () { res.close(); });
					res.openAsync();
				});
				break;
			case "AccountInOtherDomain":
				createPopup({
					dialogId: 'AccountInOtherDomain',
					view: 'AccountInOtherDomainDialog',
					forceRefresh: true,
					data: {
						accountId: loginResult.AccountId,
						alreadyTriedEmail: accountInOtherDomainCalled > 0
					}
				}).then(res => {
					initForm(
						expressQuerySelector(res.popup, '.technical-account-in-other-domain-form'),
						{
							onSubmitForm: formData => {
								res.close();
								return loginUserAtSmartPhoto(uid, accessToken, expire, mediaStorePartnerType, formData["email"], skipLogin, false, async);
							},
							submitOnEnter: true
						});

					res.openAsync();
				});
				accountInOtherDomainCalled++;
				break;
			case "ShowNewsletter":
				createPopup({
					dialogId: 'ShowNewsletter',
					view: 'ShowNewsletterDialog',
					data: {
					}
				}).then(res => {
					const redirectUrl = loginResult.redirectUrl;
					const confirmShowNewsletter = async (isChecked: boolean): Promise<void> => {
						const updatedResult = await postRequest('/ajaxxhr/Login/UpdateNewsletterCheckBox', {
                            personId: loginResult.PersonId,
                            isChecked: isChecked
                        }, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
                        res.close();
                        redirectUrl &&
                            window.setTimeout(function() {
                                window.location.href = redirectUrl.replace(/'/g, '');
                            }, 0);
					};

					initForm(
						expressQuerySelector(res.popup, '.technical-show-newsletter-form'),
						{
							onSubmitForm: formData => confirmShowNewsletter(formData["newslettersso"] === 'true'),
							submitOnEnter: true
						}
					);
					res.openAsync();
				});
				break;
			case 'ConfirmEmail':
				createPopup({
					dialogId: 'confirmation-email',
					view: 'GenericPopup',
					forceRefresh: true,
					data: {
						TitleLabel: 'Label.SingleSignOn.ConfirmationEmailTitle',
						BodyLabel: 'Label.SingleSignOn.ConfirmationEmailQuestion',
						OkButtonLabel: 'Label.ContinueButtonText',
						ExtraPopupCss: 'p-default',
						ExtraHeaderCss: 'm-popup__header__title a-typography__no-decoration',
						BodyReplacements: [loginResult.Email, mediaStorePartnerLabel]
					}
				}).then(res => {
					const continueButton = expressQuerySelector(res.popup, '.technical-general-popup-ok-button', true);
					const singleSignOnConfirmationEmail = async (dialog: IPopup) => {
						try {
                            const _ = await postHtmlRequest('/ajaxxhr/Login/SingleSignOnConfirmEmailWs',
                                {
                                    email: loginResult.Email,
                                    tempAccountId: loginResult.tempAccountId,
                                    mediastorePartnerType: mediaStorePartnerType,
                                    returnUrl: 'default'
                                },
                                { headers: { 'Content-type': 'application/json; charset=utf-8' } }
                            );
                            if (dialog)
                                dialog.close();
                        } catch (__1) {
                            if (dialog)
                                dialog.close();
                        }
					};
					continueButton.addEventListener('click', _ => singleSignOnConfirmationEmail(res));
					res.openAsync();
				});
				break;
			default:
				createPopup({
					dialogId: 'generalError',
					view: 'GenericPopup',
					data: {
						BodyLabel: 'Label.Profile.SingleSignOn.' + getMediaParterForLabel(mediaStorePartnerType) + '.GeneralError',
						OkButtonLabel: 'Label.OkButtonText',
						ExtraPopupCss: `p-default`,
						BodyReplacements: [mediaStorePartnerLabel]
					}
				}).then(res => {
					const okButton = expressQuerySelector<HTMLElement>(res.popup, '.technical-general-popup-ok-button', true);
					okButton.addEventListener("click", function () { res.close(); });
					res.openAsync();
				});
				break;
		}
	};
	const register = async (data): Promise<void> => {
		try {
            const res = await postRequest<IExpressRegisterRequest, IExpressRegisterResponse>('/ajaxxhr/Login/Registration', {
                Email: data['email'],
                Password: stringToBase64(data['password']),
                ConfirmedPassword: stringToBase64(data['password']),
                PersonalMessage: data['personalmessage'] || 'false',
                NewsLetter: data['newsletter'],
                Conditions: data['termsandconditions'],
                RedirectUrl: decodeURIComponent(getParameter('ReturnUrl') || '')
            },
                { headers: { 'Content-type': 'application/json; charset=utf-8' } });
            if (res.switchToLogin && res.success)
                toggleElementClicked(loginMode);

            if (res.success) {
                const datalayer = window.dataLayer || [];
                datalayer.push({ 'event': 'fullAccount' });
                const iframe = expressQuerySelector<HTMLIFrameElement>(document, '#dataLayerIframe', false);
                if (iframe && iframe.contentWindow && (iframe.contentWindow as any).pushDataLayer)
                    (iframe.contentWindow as any).pushDataLayer({ 'event': 'fullAccount' });

                if (!getParameter('embed')) {
                    // redirect
                    onSuccess ? onSuccess() : window.location.href = res.redirectUrl ? res.redirectUrl : window.location.origin;
                } else if (typeof parent != undefined) {
                    // @ts-ignore
                    parent.eXpress && parent.eXpress.loggedOn && parent.eXpress.loggedOn({ accountId: res.accountId, cartId: res.cartId });
                    parent.document.dispatchEvent(new CustomEvent('loggedOn.loginDialog'));
                }
            }

            if (res.message) {
                // show message in page
                formComponent.inputs['password'].setError(res.message);
            }

            if (res.isSoftDeleted) {
                const formData = {
                    Email: data['email'],
                    Password: stringToBase64(data['password']),
                    RememberMe: false,
                    RedirectUrl: decodeURIComponent(getParameter('ReturnUrl'))
                };

                // show popup
                createIsSoftDeletedPopup("ConfirmDialog", "isSoftDeleted", formData);
            }

            if (res.accountInOtherDomain) {
                // show popup
                createPopupWithBodyLabel("GenericPopup", "accountInOtherDomain", res.accountInOtherDomain);
            }

            if (res.accountBlocked) {
                // show popup
                createPopupWithBodyLabel("AccountBlocked", "accountBlocked", res.accountBlocked);
            }
            const _ = undefined;
            return;
        } catch (err) {
            const __1 = await postHtmlRequest("/apicore/logapi/LogInformation", { InformationText: `user registerd with express with errors ${err}`, Error: true }, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
            return;
        }
	};

	const formComponent = initForm(form, {
		onSubmitForm: !loginMode ? login : register,
		alternativeButton: expressQuerySelector<HTMLButtonElement>(containerEl, '.technical-submit-button')
	});

	externalLoginSubmitButtons.forEach(x => externalLogin(x, ExternalLoginProvider[x.dataset.provider]));
	toggleEl && toggleEl.addEventListener('click', e => { e.preventDefault(); toggleElementClicked(loginMode); });
	const openForgotPasswordPopup = (successMsg?: string) => {
		createPopup({
			dialogId: 'forgotPassword',
			view: 'ForgotPasswordDialog',
			forceRefresh: true,
			closeOnClickOutsidePopup: true
		}).then(res => {
			const formEl = expressQuerySelector<HTMLFormElement>(res.popup, '.technical-forgot-password-form');
			initForm(formEl, {
				onSubmitForm: (data: { [inputName: string]: string }) => postRequest('/apicore/authenticationapi/forgotpassword', { email: data['email'] }, { headers: { 'Content-type': 'application/json; charset=utf-8' } }).then(_ => {
					if (successMsg && deps.pageLogEl) {
						successMsg = successMsg.replace("[Email]", data['email']);
						const pageLog = createPageLog(deps.pageLogEl);
						pageLog.updateMessageAndStyle(PageLogStyle.Success, successMsg, true);
						if (closeLoginPopup) closeLoginPopup();
					}

					res.close();
				}),
				submitOnEnter: true
			});
			res.openAsync();
		});
	};
	forgetPassword && forgetPassword.addEventListener('click', e => {
		e.preventDefault();
		const successMsg = forgetPassword.dataset.successmsg || '';
		openForgotPasswordPopup(successMsg);
	});

	return {
		loginMode,
		toggleShow: (force?: boolean) => {
			containerEl.classList.toggle('u-hide', force);

			if (force) return;

			getFullQuerystring().includes("embed") && containerEl.scrollIntoView({ inline: 'start', block: 'start', behavior: 'auto' });
			history.replaceState(null, '', updateParameter('mode', containerEl.dataset.mode).toLowerCase());
		}
	};
}



const enableButton = (button: IButton) => {
	button.enable();

	const item = signInButtons.filter(b => b.Button == button)[0];
	item && expressRemoveClass(item.ButtonEl, 'skeleton-loading');
};

const enableAllButtons = (provider: ExternalLoginProvider) => {
	signInButtons.filter(b => b.Provider == provider).forEach(b => {
		enableButton(b.Button);
	});
};

let gapiLoaded = false;
export function createGoogleLogin(googleLoginButtonEl: HTMLButtonElement, deps: { appId: string, loginCallback: (uid: string, accessToken: string, expire: number, scope: string) => Promise<void>, onInit?: () => void }) {
	const { appId, loginCallback, onInit } = deps;
	const scope = "profile email https://www.googleapis.com/auth/photoslibrary.readonly";
	const errorsToIgnore = ["popup_closed_by_user", "access_denied"];
	let disposableEvent: IDisposable;

	const googleButton = createButton(googleLoginButtonEl);
	signInButtons.push({ Provider: ExternalLoginProvider.Google, ButtonEl: googleLoginButtonEl, Button: googleButton});

	const onClick = (ev: Event) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}
		ev.stopPropagation();

		// Google popups reuse the same popup. Enable all buttons before opening/reloading the popup
		enableAllButtons(ExternalLoginProvider.Google);
		googleButton.disable();
	};

	const initClient = () => {
		// gapi not yet ready
		if (!gapiLoaded) {
			setTimeout(initClient, 50);
			return;
		}

		gapi.auth2.init({
			client_id: appId,
			fetch_basic_profile: true,
			scope
		}).then(auth => {
			onInit && onInit();
			disposableEvent = expressEventListener(googleLoginButtonEl, 'click', onClick);
			auth.attachClickHandler(
				googleLoginButtonEl,
				{
					scope,
					prompt: "select_account consent"
				},
				(user) => {
					const authResponse = user.getAuthResponse(true);
					loginCallback(
						user.getBasicProfile().getId(),
						authResponse.access_token,
						authResponse.expires_in,
						authResponse.scope
					).finally(() => enableAllButtons(ExternalLoginProvider.Google));
				},
				(reason) => { // sign in failed
					enableAllButtons(ExternalLoginProvider.Google);
					const error = reason["error"];
					if (error && !errorsToIgnore.some((value) => value === error)) {
						throw new Error(error);
					}
				}
			);
			enableButton(googleButton);
		}, (reason) => { // init client failed
			enableButton(googleButton); // Enable to remove skeleton-loading
			googleButton.disable(); 
			throw new Error(`${reason.error} : ${reason.details}`);
		});
	};

	// Add the script only once
	const googleScriptSrc = 'https://apis.google.com/js/client:platform.js';
	const scripts = document.getElementsByTagName('script');
	for (const item of scripts) {
		if (item.src === googleScriptSrc) {
			// Script-tag already exists. Manually call init-func
			initClient();
			return;
		}
	}

	// load google script async
	const scriptEl = document.createElement('script');
	scriptEl.type = 'text/javascript';
	scriptEl.async = true;
	scriptEl.defer = true;
	scriptEl.onload = () => gapi.load('client:auth2', () => { gapiLoaded = true; initClient(); });
	scriptEl.src = googleScriptSrc;
	const firstScriptEl = document.getElementsByTagName('script')[0];
	firstScriptEl.parentElement!.insertBefore(scriptEl, firstScriptEl);
}

export function createFacebookLoginButton(facebookLoginButtonEl: HTMLButtonElement, deps: ICreateFacebookLoginButtonDependencies) {
	const { loginUserAtSmartPhoto } = deps;
	let disposableEvent: IDisposable;
	let isFacebookInitialized = false;
	let facebookLoggedIn = false;
	let onOrderFlow = false;

	const scopePhotos = 'user_photos';
	let scopeExtra = '';
	const scopeEmail = 'email';
	let facebookScope = scopePhotos + ',' + scopeEmail;
	const mediaStorePartnerType = 'facebook';

	const facebookButton = createButton(facebookLoginButtonEl);
	signInButtons.push({ Provider: ExternalLoginProvider.Facebook, ButtonEl: facebookLoginButtonEl, Button: facebookButton });

	const onClick = (ev: Event) => {
		if (ev.preventDefault) {
			ev.preventDefault();
		}
		ev.stopPropagation();
		facebookButton.disable();
		const perms = facebookLoginButtonEl.dataset.perms ? facebookLoginButtonEl.dataset.perms : '';
		loginToFacebook(false, perms, true);
	};

	const init = () => {
		if (typeof (FB) === "undefined")
			return;

		if (!isFacebookInitialized) {
			const appId = facebookLoginButtonEl.dataset.appid ? facebookLoginButtonEl.dataset.appid : '';
			initFacebook(appId);
		}

		disposableEvent = expressEventListener(facebookLoginButtonEl, 'click', onClick);
		enableButton(facebookButton);

		FB.getLoginStatus(function (loginresponse) {
			if (loginresponse.status === 'connected') {
				const uid = loginresponse.authResponse.userID;
				/* make the API call */
				FB.api(
					"/" + uid + "/permissions",
					function (response) {
						facebookLoggedIn = false;
						if (response && !response.error) {
							if (response.data && response.data.length > 0 && response.data[0] !== null) {
								if (response.data[0].email === 1 && response.data[0].user_photos === 1) {
									facebookLoggedIn = true;
								}
							}
						}
					}
				);
			} else if (loginresponse.status === 'not_authorized') {
				// the user is logged in to Facebook, but has not authenticated your app
				facebookLoggedIn = false;
			} else {
				// the user isn't logged in to Facebook.
				facebookLoggedIn = false;
			}
		}, true);
	};

	const initFacebook = (appId: string) => {
		FB.init({ appId: appId, status: false, cookie: true, version: 'v18.0' }); //status: false check the login status upon init -> we will do this with getLoginStatus
		isFacebookInitialized = true;
	};

	const fBLoginWithPermissions = (skipLogin, async) => {

		//showLoadingDialog();
		// fbdialogTimer = window.setTimeout(HideLoadingDialog, 3000);
		FB.login(function (loginresponse) {
			// clearTimeout(fbdialogTimer);
			// connected
			if (loginresponse.authResponse) {
				// check if all permissions are granted (result is also 'connected' when permissions are partialy granted)
				const uid = loginresponse.authResponse.userID;
				//showLoadingDialog();
				FB.api(
					"/" + uid + "/permissions",
					function (response) {
						facebookLoggedIn = false;
						if (response && !response.error) {
							if (response.data && response.data.length > 0 && checkFacebookPermissions(response.data)) {
								// user granted extended permissions
								const accessToken = loginresponse.authResponse.accessToken;
								const expireTime = loginresponse.authResponse.expiresIn;
								loginUserAtSmartPhoto(uid, accessToken, expireTime, mediaStorePartnerType, '', skipLogin, onOrderFlow, async)
									.finally(() => enableButton(facebookButton));
								facebookLoggedIn = true;
							} else {
								// user cancelled extended permissions
							}
							//hideLoadingDialog();
						}
					}
				);
			}

			if (!facebookLoggedIn)
				enableButton(facebookButton);

			//hideLoadingDialog();
		}, { scope: facebookScope });
	};

	const loginToFacebook = (skipLogin, extraPermissions, async) => {
		if (typeof (FB) === "undefined")
			return;

		if (skipLogin === null) skipLogin = false;
		onOrderFlow = async;
		scopeExtra = extraPermissions;
		if (scopeExtra && scopeExtra !== '')
			facebookScope = facebookScope + ',' + scopeExtra;

		if (facebookLoggedIn) {
			// are we still connected facebook?
			//showLoadingDialog();
			FB.getLoginStatus(function (loginresponse) {
				if (loginresponse.status === 'connected') {
					// the user is logged in and has authenticated your app, and response.authResponse supplies
					// the user's ID, a valid access token, a signed request, and the time the access token and signed request each expire
					const uid = loginresponse.authResponse.userID;
					//showLoadingDialog();
					FB.api(
						"/" + uid + "/permissions",
						function (response) {
							facebookLoggedIn = false;
							if (response && !response.error) {
								// Check if permissions are granted.
								// NOTE: We do not currently check the extraPermissions
								// But they are currently always empty on our website.
								if (response.data && response.data.length > 0 && checkFacebookPermissions(response.data)) {
									const accessToken = loginresponse.authResponse.accessToken;
									const expireTime = loginresponse.authResponse.expiresIn;
									loginUserAtSmartPhoto(uid, accessToken, expireTime, mediaStorePartnerType, '', skipLogin, onOrderFlow, async)
										.finally(() => enableButton(facebookButton));
								} else {
									fBLoginWithPermissions(skipLogin, async);
								}
								//hideLoadingDialog();
							}
						}
					);
				} else if (loginresponse.status === 'not_authorized') {
					// the user is logged in to Facebook, but has not authenticated your app
					fBLoginWithPermissions(skipLogin, async);
				} else {
					// the user isn't logged in to Facebook.
					fBLoginWithPermissions(skipLogin, async);
				}
				//hideLoadingDialog();
			}, true);
		} else {
			fBLoginWithPermissions(skipLogin, async);
		}
	};

	const checkFacebookPermissions = (data) => {

		// todo: this is the old validation, can be removed after the fb apps are swithched to Oauth2.0
		if (data[0] !== null && typeof data[0].email !== "undefined" && typeof data[0].user_photos !== "undefined") {
			return data[0] !== null && data[0].email === 1 && data[0].user_photos === 1;
		}

		const email: any = (data as any[]).filter(e => e.permission === "email");
		const photos: any = (data as any[]).filter(e => e.permission === "user_photos");

		const emailGranted = email != null && email[0].status === "granted";
		const photosGranted = photos != null && photos[0].status === "granted";

		return emailGranted === photosGranted === true;
	};

	const deInit = () => disposableEvent && disposableEvent.dispose();

	init();

	return {
		dispose: deInit,
	};
}
